import { render, staticRenderFns } from "./SManagerEduMoralStuData.vue?vue&type=template&id=7603daac&scoped=true"
import script from "./SManagerEduMoralStuData.vue?vue&type=script&lang=js"
export * from "./SManagerEduMoralStuData.vue?vue&type=script&lang=js"
import style0 from "./SManagerEduMoralStuData.vue?vue&type=style&index=0&id=7603daac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7603daac",
  null
  
)

export default component.exports