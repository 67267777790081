<!-- 德育管理 > 班级管理 > 学生量化 -->
<template>
    <div>
        <div
            v-if="!errorShow"
            class="right-panel"
        >
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-main"
            >
                <!-- 头部筛选 -->
                <div class="filter-wrap">
                    <expand-filter
                        :closeWidth="1317"
                        :formData="formData"
                        marginBottom="0px"
                        @clickBtn="clickBtn"
                        @changeSel="changeSel"
                        @changeBtnFormType="changeBtnFormType"
                    >
                        <template #datePicker="{slotData}">
                            <div class="date-picker-wrap">
                                <el-date-picker
                                    style="width:330px;"
                                    v-model="slotData.value"
                                    :clearable="false"
                                    type="datetimerange"
                                    start-placeholder="请选择开始时间"
                                    end-placeholder="请选择结束时间"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                    :default-time="['00:00:00', '23:59:59']"
                                >
                                </el-date-picker>
                            </div>
                        </template>
                        <template #filter_progress="{data}">
                            <div class="ec-input-range">
                                <el-input
                                    v-model="listQuery.minScore"
                                    style="width: 72px"
                                    @input="handleScore($event)"
                                    placeholder="最小分数"
                                ></el-input>
                                <div class="ec-input-range-divider"></div>
                                <el-input
                                    v-model="listQuery.maxScore"
                                    style="width: 72px"
                                    @input="handleMaxScore($event)"
                                    placeholder="最大分数"
                                ></el-input>
                            </div>
                        </template>
                        <div
                            class="filter_Btn"
                            slot="other-btn"
                        >
                            <div class="line"></div>
                            <el-button
                                style="margin-left: 0"
                                :loading="exportLoading"
                                v-has-permi="[
                                    'moralmanage:classmanage:studata:export',
                                ]"
                                type="primary"
                                @click="handleExport()"
                                >下载统计</el-button
                            >
                        </div>
                    </expand-filter>
                </div>
                <!-- 列表 -->
                <table-data
                    ref="table"
                    v-loading="loadingTable"
                    :config="table_config"
                    :tableData="table_data"
                >
                    <template #studentName="{data}">
                        <el-button
                            type="text"
                            @click="recordPopup(data)"
                            :disabled="
                                !hasPermission([
                                    'moralmanage:classmanage:studata:detail',
                                ])
                            "
                            >{{ data.studentName }}</el-button
                        >
                    </template>
                </table-data>
                <!-- 分页 -->
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                />

                <!-- 德育考核记录 -->
                <dialog-wrapper
                    :dialogObj="dialogObj"
                    @handleClose="handleClose"
                >
                    <moral-education-assessment-records
                        :stuTotal="stuTotal"
                        :objStudentId="objStudentId"
                        :dialogTableList="dialogTableList"
                        :stuLoadingTable="stuLoadingTable"
                        :moralmanageClassManageStuDataCheckPerson="
                            moralmanageClassManageStuDataCheckPerson
                        "
                        @handleImg="handleImg"
                        @toogleExpand="toogleExpand"
                        @handleDelete="handleDelete"
                        @exportStu="exportStu"
                        @handleClose="handleClose"
                        @handleProcessing="handleProcessing"
                        @deleteProcessing="deleteProcessing"
                        @getList="getList"
                        @recordPopup="recordPopup"
                    >
                    </moral-education-assessment-records>
                </dialog-wrapper>
                <dialog-wrapper
                    :dialogObj="dialogObjPop"
                    @handleClose="handleClosePop"
                >
                    <add-processing-records
                        v-if="dialogObjPop.dialogVisible"
                        :processingRecords="processingRecords"
                        :processingDictionaryList="processingDictionaryList"
                        @submitForm="submitForm"
                        @resetForm="resetForm"
                    >
                    </add-processing-records>
                </dialog-wrapper>
                <!-- 点击查看图片 -->
                <el-image-viewer
                    style="z-index: 9999"
                    v-if="showViewer"
                    :on-close="handleCloseImg"
                    :url-list="[viewPhoto]"
                />
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    // FilterData,
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
} from "common-local";
import {mapState} from "vuex";
import {downloadFile} from "@/libs/utils.js";
import ExpandFilter from "./Sub/ExpandFilter.vue";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import MoralEducationAssessmentRecords from "./SManagerEduMoralStuData/MoralEducationAssessmentRecords.vue";
import AddProcessingRecords from "./SManagerEduMoralStuData/AddProcessingRecords.vue";
import {hasPermission} from "@/libs/utils";
import {SManagerEduMoralStuDataModule} from "@/models/SManagerEduMoralStuData.js";
import onResize from "@/mixins/onResize";
import TableData from "@/components/scrollWrapper/Sub/TableData"
export default {
    mixins: [onResize],
    components: {
        DialogWrapper,
        ExpandFilter,
        // 功能组件
        Error,
        Loading,
        Pagination,
        TableData,
        ElImageViewer,
        MoralEducationAssessmentRecords,
        AddProcessingRecords,
    },

    data() {
        return {
            hasPermission,
            objStudentId: {},
            listQuery: {
                schoolId: "",
                stuName: "",
                pageNum: 1,
                pageRow: 20,
                gradeId: "",
                classId: "",
                assessDateStart: "",
                assessDateEnd: "",
                maxScore: "",
                minScore: "",
                dataScope: "",
            },
            subjects: [],
            total: 0,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "请输入学生姓名",
                        key: "stuName",
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择年级",
                        key: "gradeId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "请选择班级",
                        key: "classId",
                        list: [],
                    },
                    {
                        type: "slot",
                        slotName: "filter_progress",
                        fixed: false,
                        label: "",
                        value: [],
                        key: "progress",
                    },
                    {
                        type: "slot",
                        slotName: "datePicker",
                        key: "dateArr",
                        value: [],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["moralmanage:classmanage:studata:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["moralmanage:classmanage:studata:list"],
                    },
                ],
                btnFormType: true,
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        type: "slot",
                        slotName: "studentName",
                        fixed: false,
                        label: "学生姓名",
                        // labelWidth: "200"
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班主任",
                        prop: "masterName",
                        type: "tooltipColumn",
                        labelWidth: "220",
                        splitSign: ",",
                        align: 'center'
                    },
                    {
                        label: "学号",
                        prop: "studyNo",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        align: 'center'
                    },
                    {
                        label: "德育考核分",
                        prop: "moralScore",
                        type: "function",
                        align: 'center',
                        callBack(row) {
                            if (row.moralScore >= 0) {
                                return `<div style="color:#71CE66;">${row.moralScore}</div>`;
                            } else if (row.moralScore < 0) {
                                return `<div style="color:#F99D5E;">${row.moralScore}</div>`;
                            } else {
                                return "-";
                            }
                        },
                    },
                ],
                check: false,
                showIndex: false,
                height: "",
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            stuLoadingTable: true,
            exportLoading: false,
            // 考核记录
            dialogObj: {
                title: "",
                dialogVisible: false,
                width: "1000px",
            },
            dialogObjPop: {
                title: "添加处理记录",
                dialogVisible: false,
                width: "460px",
            },
            dialogTableList: [],
            stuTotal: "0",
            firstTime: true,
            schoolCalendar: [],
            showViewer: false,
            viewPhoto: "",
            gradeList: [],
            moralmanageClassManageStuDataCheckPerson: false, // 控制弹窗是否展示检查人
            processingRecords: {}, // 德育考核记录添加处理结果
            processingDictionaryList: [],
            classAssessmentRecords: {},
        };
    },
    created() {
        this.init();
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.firstTime) {
            this.initBaseData("activated");
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
            permissions: state => state.permissions,
        }),
        action() {
            return "/api/school/schoolDormitoryDistribution/upload";
        },
        // 下载模板
        downLoadTempBtn() {
            return ["dormitoryDistribution:downLoadTemp"];
        },
    },
    methods: {
        init() {
            this.initAssignment();
            this.initFilter();
            this.initTableConfig();
            this.initBaseData("created");
            this.initPageData();
        },
        initAssignment() {
            this.firstTime = false;
            this.listQuery.schoolId = this.schoolId;
        },
        initFilter() {
            let moralmanageClassmanageStudataDataRange = this.permissions.some(
                (item) => {
                    return "moralmanage:classmanage:studata:dataRange" == item;
                },
            );
            if (!moralmanageClassmanageStudataDataRange) return;
            let idx = 0,
                bol = false;
            let insertValue = {
                type: "select",
                label: "",
                value: "1",
                placeholder: "全部数据",
                key: "dataScope",
                list: [
                    {
                        label: "全部数据",
                        value: "1",
                    },
                    {
                        label: "不含本班考核数据",
                        value: "2",
                    },
                ],
            };
            this.formData.data.forEach((item, index) => {
                if (item.key == "classId") {
                    idx = index + 1;
                }

                if (item.key == "dataScope") {
                    bol = true;
                }
            });

            if (!bol) {
                this.formData.data.splice(idx, 0, insertValue);
                this.listQuery.dataScope = "1";
            }
        },
        initTableConfig() {
            this.moralmanageClassManageStuDataCheckPerson =
                this.permissions.some((item) => {
                    return (
                        "moralmanage:classmanage:studata:checkPerson" == item
                    );
                });
        },
        /**
         * @Description: 校验最小分数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-14 16:11:16
         * @param {*} e
         */
        handleScore(e) {
            let value = e.replace(/[^\-\d.]/g, ""); // 只能输入.和-和数字
            value = value.replace(/^\./g, ""); //第一个字符不能是.
            value = value.replace(/\.{2,}/g, "."); // 不能连续输入.
            value = value.replace(/(\.\d+)\./g, "$1"); // .后面不能再输入.
            value = value.replace(/(-)\./g, "$1"); // -后面不能输入.
            value = value.replace(/\-{2,}/g, "-"); // -只能保留一个
            value = value.replace(/(\d+|\.)-/g, "$1"); // 数字和.后面不能接-,不能出现类似11-, 12.-
            value = value.replace(/-(0){2,}/g, "$1"); // 不能出现-00,-001,-0001类似
            value = value.replace(/(-)0+(\d+)/g, "$1$2"); // 不能出现-01,-02类似
            value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
            value = value.replace(/(\d{10})\d*/, "$1"); // 最多保留10位整数
            value = value.replace(/(\.\d{1})\d*/, "$1"); // 最多保留1位小数
            this.listQuery.minScore = value;
        },
        /**
         * @Description: 校验最大分数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-14 16:11:41
         * @param {*} e
         */
        handleMaxScore(e) {
            let maxvalue = e.replace(/[^\-\d.]/g, ""); // 只能输入.和-和数字
            maxvalue = maxvalue.replace(/^\./g, ""); //第一个字符不能是.
            maxvalue = maxvalue.replace(/\.{2,}/g, "."); // 不能连续输入.
            maxvalue = maxvalue.replace(/(\.\d+)\./g, "$1"); // .后面不能再输入.
            maxvalue = maxvalue.replace(/(-)\./g, "$1"); // -后面不能输入.
            maxvalue = maxvalue.replace(/\-{2,}/g, "-"); // -只能保留一个
            maxvalue = maxvalue.replace(/(\d+|\.)-/g, "$1"); // 数字和.后面不能接-,不能出现类似11-, 12.-
            maxvalue = maxvalue.replace(/-(0){2,}/g, "$1"); // 不能出现-00,-001,-0001类似
            maxvalue = maxvalue.replace(/(-)0+(\d+)/g, "$1$2"); // 不能出现-01,-02类似
            maxvalue = maxvalue.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
            maxvalue = maxvalue.replace(/(\d{10})\d*/, "$1"); // 最多保留10位整数
            maxvalue = maxvalue.replace(/(\.\d{1})\d*/, "$1"); // 最多保留1位小数
            this.listQuery.maxScore = maxvalue;
        },
        /**
         * @Description: 点击图片放大
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-13 16:35:07
         * @param {*} data
         */
        handleImg(data) {
            this.viewPhoto = data;
            this.showViewer = true;
        },
        handleCloseImg() {
            this.showViewer = false;
        },
        async initBaseData(key) {
            await this.getSchoolCalendar();
            await this.getGradeList();
            // if (key === "created") {

            // }
            this.setDefaultDate();
            this.getList();
        },
        /**
         * @Description: 获取页面所需基础数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-27 09:46:18
         */
        initPageData() {
            this.getMoralDisposeTypeList();
        },
        /**
         * @Description: 获取考核行为处理方式字典列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-27 09:48:26
         */
        getMoralDisposeTypeList() {

            const sManagerEduMoralStuDataModule =
                new SManagerEduMoralStuDataModule();
            sManagerEduMoralStuDataModule
                .getMoralDisposeTypeList({dictKey: "moralDisposeTypeGroup"})
                .then((res) => {
                    this.$_resizeHandler()
                    console.log(res,'---res.data.code')
                    if (!res.error && res.data.code == "200") {
                        console.log(res.data.data, "处理方式字典列表");
                        this.processingDictionaryList = res.data.data;
                    } else if (!res.error && res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        handleVal() {
            const _this = this;

            if (
                Number(_this.listQuery.minScore) >
                Number(_this.listQuery.maxScore)
            ) {
                _this.$message({
                    type: "warning",
                    message: "最小分数需要小于最大分数!",
                    onClose: function () {
                        _this.listQuery.minScore = "";
                        _this.listQuery.maxScore = "";
                    },
                });
            }
        },
        /**
         * @Description: 查询校历
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-10 09:16:14
         */
        async getSchoolCalendar() {
            let params = {
                schoolId: this.schoolId,
                currentDate: this.$moment().format("YYYY-MM-DD HH:MM:SS"),
            };
            await this._fet(
                "/school/schoolCalendar/listByCondition",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.schoolCalendar = res.data.data;
                }
            });
        },
        /**
         * @Description: 设置默认查询时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-04-13 09:48:03
         */
        setDefaultDate() {
            this.listQuery.assessDateStart = this.$moment(new Date())
                .subtract(7, "days")
                .format("YYYY-MM-DD HH:mm");
            this.listQuery.assessDateEnd =
                this.$moment().format("YYYY-MM-DD HH:mm");
            if (this.schoolCalendar.length > 0) {
                // 根据校历设置默认查询时间
                let {startTime, endTime} = this.schoolCalendar[0];
                startTime = this.formatDate(startTime);
                endTime = this.formatDate(endTime);
                if (
                    this.listQuery.assessDateEnd < endTime &&
                    this.listQuery.assessDateEnd > startTime
                ) {
                    this.listQuery.assessDateStart = startTime.concat(" 00:00");
                }
            }
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [
                        this.listQuery.assessDateStart,
                        this.listQuery.assessDateEnd,
                    ];
                }
            });
        },
        formatDate(date) {
            return date.replace(/[年月]/g, "-").replace(/[日]/, "");
        },
        /**
         * @Description: 点击学生，弹出该学生的考核记录弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 11:01:03
         */
        recordPopup(item) {
            this.classAssessmentRecords = item;
            console.log(item, "-item-");
            this.dialogObj.dialogVisible = true;
            this.dialogObj.title = item.studentName + "德育考核记录";
            this.objStudentId = item;
            const params = {
                studentId: item.id,
                schoolId: this.schoolId,
                assessDateStart: this.listQuery.assessDateStart,
                assessDateEnd: this.listQuery.assessDateEnd,
                dataScope: this.listQuery.dataScope,
            };
            this.stuLoadingTable = true;
            this._fet(
                "/school/schoolMoralStudentDetail/getStuQuantizationDetail",
                params,
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        const data = res.data.data.studentBehaviorList;
                        this.stuTotal = res.data.data.totalScore;
                        data.forEach((item, index) => {
                            this.$set(item, "processingResultsType", 0);
                            this.$set(item, "processingLoading", false);
                            this.$set(item, "processingList", []);
                        });
                        this.dialogTableList = data;
                        this.dialogTableList.forEach(iv=>{
                            if (iv.img) {
                                iv.img = iv.img.split(",");
                            } else {
                                iv.img = []
                            }
                        })
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.stuLoadingTable = false;
                })
                .catch(() => {
                    this.stuLoadingTable = false;
                });
        },
        /**
         * @Description: 获取学生量化列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 11:03:46
         * @param {*} t
         */
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            this._fet(
                "/school/schoolMoralStudentDetail/getStuQuantizationList",
                this.listQuery,
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        const list = res.data.data.list;
                        this.table_data = list;
                        this.total = res.data.data.totalCount;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch(() => {
                    this.loadingTable = false;
                });
        },
        /**
         * @Description: 获取年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 11:07:18
         */
        async getGradeList() {
            let params = {
                schoolId: this.schoolId,
                jobScope: ["2", "3"],
                needPermissionConfig: '0'
            };
            await this._fet(
                "/school/schoolOrgan/getGradeListByMoralManage",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.gradeList = res.data.data.map((i) => ({
                        label: i.name,
                        value: i.id,
                    }));
                    this.setFormDataList("gradeId", this.gradeList);
                }
            });
        },
        /**
         * @Description: 获取班级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 17:50:59
         */
        getClassList(gradeId) {
            let params = {
                schoolId: this.schoolId,
                gradeId: gradeId,
                jobScope: ["2", "3"],
                needPermissionConfig: '0'
            };
            this._fet(
                "/school/schoolOrgan/getClassListByMoralManage",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    let classList = res.data.data.map((i) => ({
                        label: i.name,
                        value: i.id,
                    }));
                    this.setFormDataList("classId", classList);
                }
            });
        },
        setFormDataList(type, list) {
            const index = this.formData.data.findIndex(
                (item) => item.key === type,
            );
            if (index > -1) {
                this.formData.data[index].list = list;
            }
        },
        // 修改年级查询项
        changeSel(data) {
            // 修改统计范围
            if (data.key === "gradeId") {
                const classIndex = this.formData.data.findIndex(
                    (i) => i.key === "classId",
                );
                this.formData.data[classIndex].value = "";
                this.formData.data[classIndex].list = [];
                if (data.value) {
                    this.getClassList(data.value);
                }
            }
        },
        /**
         * @Description: 点击查询/重置按钮
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 11:07:57
         * @param {*} data
         */
        clickBtn(data) {
            switch (data.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        if (item.key === "dateArr") {
                            if (item.value && item.value.length > 0) {
                                this.listQuery.assessDateStart = item.value[0];
                                this.listQuery.assessDateEnd = item.value[1];
                            } else {
                                this.listQuery.assessDateStart = "";
                                this.listQuery.assessDateEnd = "";
                            }
                        } else {
                            this.listQuery[item.key] = item.value;
                        }
                        if (item.key === "progress") {
                            if (
                                this.listQuery.minScore &&
                                this.listQuery.maxScore
                            ) {
                                if (
                                    Number(this.listQuery.minScore) >
                                    Number(this.listQuery.maxScore)
                                ) {
                                    this.$message({
                                        type: "warning",
                                        message: "最小分数需要小于最大分数!",
                                    });
                                    this.listQuery.minScore = "";
                                    this.listQuery.maxScore = "";
                                    return;
                                }
                            }
                        }
                    });
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        item.value = "";
                        this.listQuery[item.key] = "";
                    });
                    this.listQuery.minScore = "";
                    this.listQuery.maxScore = "";
                    this.setDefaultDate();
                    this.getList(1);
                    break;
            }
        },

        /**
         * @Description: 导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-03-28 17:55:29
         */
        handleExport() {
            this.exportLoading = true;
            let form = {
                schoolId: this.listQuery.schoolId,
                stuName: this.listQuery.stuName,
                gradeId: this.listQuery.gradeId,
                classId: this.listQuery.classId,
                assessDateStart: this.listQuery.assessDateStart,
                assessDateEnd: this.listQuery.assessDateEnd,
                maxScore: this.listQuery.maxScore,
                minScore: this.listQuery.minScore,
                dataScope: this.listQuery.dataScope,
            };

            downloadFile(
                {
                    url: "/school/schoolMoralStudentDetail/stuQuantizationListExport",
                    form,
                },
                () => {
                    // this.$message.success("导出成功");
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
        },
        /*
            学生量化考核记录
            exportStu        下载详情
            handleClose         关闭
            handleDelete        删除
         */
        exportStu() {
            let stuForm = {
                schoolId: this.schoolId,
                studentId: this.objStudentId.id,
                assessDateStart: this.listQuery.assessDateStart,
                assessDateEnd: this.listQuery.assessDateEnd,
                classId: this.objStudentId.classId,
                dataScope: this.listQuery.dataScope,
            };
            downloadFile(
                {
                    url: "/school/schoolMoralStudentDetail/stuQuantizationDetailListExport",
                    form: stuForm,
                },
                () => {
                    // this.$message.success("导出成功");
                    this.$message.success("导出成功（暂不支持视频下载）");
                },
                () => {},
            );
        },
        handleClose() {
            this.dialogObj.dialogVisible = false;
        },
        handleProcessing(row) {
            console.log(row, "row");
            this.processingRecords = row;
            this.dialogObjPop.dialogVisible = true;
        },
        /**
         * @Description: 删除处理结果
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-27 14:06:11
         * @param {*} data
         */
        deleteProcessing(data) {
            const {row, item} = data;
            const sManagerEduMoralStuDataModule =
                new SManagerEduMoralStuDataModule();
            sManagerEduMoralStuDataModule
                .deleteProcessingRecords({id: item.id})
                .then((res) => {
                    if (res.data.code == "200") {
                        this.dialogTableList.forEach((itm) => {
                            if (itm.id == row.id) {
                                itm.processingList.forEach(
                                    (subItem, subIndex) => {
                                        if (subItem.id == item.id) {
                                            itm.processingList.splice(
                                                subIndex,
                                                1,
                                            );
                                        }
                                    },
                                );
                            }
                        });
                        this.$message.success("删除成功");
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        handleDelete(data) {
            this.$confirm("确定要删除这条记录吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 发请求
                    this._fet(
                        "/school/schoolMoralStudentDetail/deleteStuDetail",
                        {id: data.id},
                    ).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success("删除成功");
                            this.recordPopup(this.objStudentId);
                            this.getList();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        /**
         * @Description: 合并表格
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-25 14:23:29
         */
        toogleExpand(row) {
            console.log(row.processingResultsType, "--row");
            if (row.processingResultsType == "1") {
                this.setDialogTableList(true, row);
                this.getProcessingRecordListQuery(row);
            }
        },
        setDialogTableList(type, row) {
            this.dialogTableList.forEach((item) => {
                if (item.id == row.id) {
                    this.$set(item, "processingLoading", type);
                }
            });
        },
        handleClosePop() {
            console.log("关闭处理记录");
            this.resetForm();
        },
        /**
         * @Description: 添加处理记录
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-26 17:35:24
         */
        async submitForm(data) {
            const sManagerEduMoralStuDataModule =
                new SManagerEduMoralStuDataModule();
            await sManagerEduMoralStuDataModule
                .addProcessingRecords(data)
                .then(async (res) => {
                    if (res.data.code == "200") {
                        this.$message.success("添加处理记录成功");
                        await this.getProcessingRecordListQuery(
                            this.processingRecords,
                        );
                        this.resetForm();

                        if (this.processingRecords.haveHandle == "0") {
                            this.setNewTable(this.classAssessmentRecords);
                        }

                        console.log(
                            this.classAssessmentRecords,
                            "this.classAssessmentRecords",
                        );
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        setNewTable(classAssessmentRecords) {
            const params = {
                studentId: classAssessmentRecords.id,
                schoolId: this.schoolId,
                assessDateStart: this.listQuery.assessDateStart,
                assessDateEnd: this.listQuery.assessDateEnd,
            };

            this._fet(
                "/school/schoolMoralStudentDetail/getStuQuantizationDetail",
                params,
            ).then((res) => {
                if (res.data.code == "200") {
                    let data = res.data.data.studentBehaviorList;

                    this.dialogTableList.forEach((item) => {
                        data.forEach((subItem) => {
                            if (item.id == subItem.id) {
                                this.$set(subItem, "processingResultsType", 0);
                                this.$set(subItem, "processingLoading", false);
                                this.$set(
                                    subItem,
                                    "processingList",
                                    item.processingList,
                                );
                                console.log('走了setNewTable???');
                            }
                        });
                    });

                    this.dialogTableList = data;
                    this.dialogTableList.forEach(ivv=>{
                        if (ivv.img) {
                            ivv.img = ivv.img.split(",");
                        } else {
                            ivv.img = []
                        }
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 获取处理记录列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-27 10:37:44
         */
        getProcessingRecordListQuery(row) {
            const sManagerEduMoralStuDataModule =
                new SManagerEduMoralStuDataModule();
            sManagerEduMoralStuDataModule
                .processingRecordListQuery({moralId: row.id})
                .then((res) => {
                    if (res.data.code == "200") {
                        this.dialogTableList.forEach((item) => {
                            if (row.id == item.id) {
                                this.$set(
                                    item,
                                    "processingList",
                                    res.data.data,
                                );
                            }
                        });

                        console.log(
                            this.dialogTableList,
                            "this.dialogTableList",
                        );
                        this.setDialogTableList(false, row);
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 关闭处理记录
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-26 17:28:56
         */
        resetForm() {
            console.log('走了resetForm???');
            this.dialogObjPop.dialogVisible = false;
        },
    },
};
</script>

<style scoped lang="scss">

.filter-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 4px;

    .expand-filter {
        padding: 0;
    }

    .operation-btn-view {
        flex-shrink: 0;
    }
}

.filter_Btn {
    .line {
        width: 1px;
        height: 24px;
        margin: 0 24px;
        border-left:1px dashed #DBDBDB;
        float: left;
        margin-top: 4px;
        vertical-align: bottom;
    }
}

.upload-demo {
    display: inline-block;
}

.img {
    width: 14px;
    float: left;
    margin-top: 2px;
    padding-right: 6px;
}

.download-btn {
    border: 0;
    background-color: #3dc5f2;

    &:hover {
        background-color: #5ecff5;
    }
}

.drawerExam-container {
    ::v-deep .v-modal {
        position: absolute;
    }
}

.ec-input-range-divider {
    display: inline-block;
    width: 7px;
    height: 1px;
    background: #c0c4cc;
    margin: 0 5px;
    margin-bottom: 4px;
}
::v-deep .el-table--border .el-table__cell {
    border-right: none;
}
::v-deep .el-button.is-disabled.el-button--text {
    color: #363b40;
}
</style>
