<template>
    <div>
        <global-page-back
            detailPageTitle="指标管理"
            @handlerGlobalType="handlerGlobalType"
        >
            <div slot="globalPageBackTabs">
                <div class="tab-switch">
                    <span @click="changeCompName('TargetManage')" v-has-permi="['moralmanage:moraldocument:room:rule']" :class="currentComp==='TargetManage'?'item-ok':''">德育指标</span>
                    <span @click="changeCompName('TargetManageDormitory')" v-has-permi="['moralmanage:moraldocument:room:rule']" :class="currentComp==='TargetManageDormitory'?'item-ok':''">宿舍指标</span>
                </div>
            </div>
        </global-page-back>
        <keep-alive include="TargetManage">
            <component
                :is="currentComp"
                :student-data="studentData"
                :detailPageTitle="detailPageTitle"
                @changeComp="changeComp"
            ></component>
        </keep-alive>
    </div>
</template>

<script>
import GlobalPageBack from "./Sub/GlobalPageBack";
import TargetManage from "@/components/scrollWrapper/SManagerEduMoralAssessment/TargetManage.vue";
import TargetManageDormitory from "@/components/scrollWrapper/SManagerEduDormitoryAssessment/TargetManage.vue";
export default {
    name: "SManagerEduMoralTarget",
    components: {
        TargetManage,
        TargetManageDormitory,
        GlobalPageBack
    },
    props:{
        studentData:{
            type:Object
        }
    },
    data() {
        return {
            currentComp: "TargetManage",
            // studentData: {},
            type: this.studentData.type,
            detailPageTitle: ''
        }
    },
    activated () {
        this.changeComp({name: this.currentComp, data: this.studentData})
    },
    created () {
        const obj = {
            1: 'TargetManage',
            2: 'TargetManageDormitory'
        }
        this.currentComp = obj[this.studentData.type]
        this.$on('handlerGlobalPageBack', () => {
            this.handlerGlobalPageBack()
        })
    },
    methods: {
        changeCompName(name){
            this.currentComp = name
        },
        changeComp(compData) {
            const { name, data } = compData
            console.log(name,data,'name')
            this.$emit("changeComp", {
                name: "AssessmentList",
            });
            // switch (name) {
            //     case 'targetManageDormitory':
            //         this.$eventDispatch('setGlobalPageType', 'detail')
            //         break;
            //     case 'targetManage':
            //         this.$eventDispatch('setGlobalPageType', 'detail')
            //         break;
            //     default:
            //         this.$eventDispatch('setGlobalPageType', 'list')
            //         break;
            // }

            // this.currentComp = name
            // this.studentData = data
        },
        handlerGlobalType() {
            this.$emit("changeComp", {
                name: "AssessmentList",
            });
            this.$emit('transfer',true)
        },
        handlerGlobalPageBack () {
            console.log('123')
            this.changeComp({name: 'TargetManage'})
        }
    }
}
</script>

<style lang="scss" scoped>
.tab-switch {
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    padding-left: 10px;
    border-radius: 5px;
    align-items: center;
    background-color: #FFFFFF;

    span {
        cursor: pointer;
        margin: 0 20px;
        font-size: 14px;
        line-height: 48px;
    }

    .item-ok {
        color: #3064BF;
        line-height: 46px;
        border-bottom: solid 2px #3064BF;
    }
}
</style>
